import React from "react";
import "../App.css";
import Card from "../components/Card";
import AboutCard from "../components/AboutCard";
import AboutCard1 from "../components/AboutCard1";
import { Helmet } from "react-helmet";

const Secondary = () => {
  return (
    
    <div className="flex flex-col">
      <Helmet>
        <title>About us</title>
          </Helmet>
      <div
        className="flex justify-center items-start w-full h-[320px] bg-cover bg-no-repeat bg-right md:bg-center p-5"
        id="secondary-banner"
      >
        <div className="flex flex-col w-[90%] md:w-[50%] h-full justify-around text-center text-gray-100">
          <h1 className="text-6xl tracking-tighter  font-sans">St. Agnes</h1>
          <p className="text-gray-200">
          Welcome to St. Agnes Vocational School, where inclusivity is a way of life. Located near Kahendero Junction on the Mbarara-Kasese road, we believe in a holistic approach to education, providing a rigorous academic curriculum in a disciplined environment. Our mission is to offer quality education to every child, regardless of their background. Join us in creating a more inclusive world through education.

          </p>
        </div>
      </div>

      <AboutCard1 />

    </div>
  );
};

export default Secondary;
