import React from "react";

const AboutCard1 = () => {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
            alt="feature"
            class="object-cover object-center h-full w-full"
            src="assets/ga6.jpg"
          />
        </div>
        <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div class="flex flex-col mb-10 lg:items-start items-center">
      
            <div class="flex-grow">
              <h2 class="text-gray-900 text-2xl title-font font-bold mb-3">
              A Holistic Approach to Education
              </h2>
              <p class="leading-relaxed text-base">
              At St. Agnes Vocational School, inclusivity isn't just a buzzword; it's a way of life. This institution is more than a place of learning; it's a community where both non-disabled and disabled students coexist harmoniously. Here, the emphasis is not just on academic excellence but also on fostering an environment of acceptance and respect.

</p>
 
            </div>
          </div>
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-bold mb-3">
              Quality Education for All
              </h2>
              <p class="leading-relaxed text-base">
             One of St. Agnes's most remarkable features is its dedication to providing quality education to even the most underprivileged students. In this inclusive haven, economic background is not a barrier to learning. Every child, regardless of their financial circumstances, is given the opportunity to access top-notch education.


The heart of St. Agnes Vocational School lies in its mission to ensure that disabled children have a comfortable, equal, and enriching educational experience. Here, they aren't treated differently; they study alongside their peers, and their abilities shine brightly.
              </p>
            </div>
          </div>
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
              
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-bold mb-3">
              A Vocational Center for RAPCD
              </h2>
              <p class="leading-relaxed text-base">

St. Agnes Vocational School goes beyond traditional education. It also serves as a vocational center for RAPCD, empowering students with practical skills like carpentry, tailoring, and knitting. This ensures that they are not just academically equipped but also prepared for self-sustainability.




              </p>
             
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-bold mb-3">
              A Model of Inclusive Education
              </h2>
              <p class="leading-relaxed text-base">

St. Agnes Vocational School isn't just an educational institution; it's a model for inclusive education in the community. It sets a precedent for how schools can be places of acceptance, learning, and growth for children of all abilities. It sends a powerful message: that in the world of education, inclusivity is not a dream but a reality that's achievable.

In the heart of Mbarara-Kasese road, St. Agnes Vocational School stands tall as a symbol of what education can be when it's centered on inclusivity, discipline, quality, and equality. It's a place where differences are celebrated, and where the potential of every child, regardless of ability or background, is nurtured and allowed to flourish.



              </p>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCard1;
