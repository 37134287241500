import React, { useLayoutEffect } from "react";
import DonationCard from "../components/DonationCard";

const Home = () => {
  return (
    <div>
      <section class="banner-1">
        <div class="diagonal-div"></div>
        <div class="diagonal-div-2"></div>

        <div class="banner-text">
          <p class="banner-heading-1">Welcome to RAPCD</p>
          <p class="banner-heading-2">Empowering Abilities, Enabling Lives!</p>
          <p class="banner-heading-3">
          Join us in our journey to make a difference in the lives of these remarkable children. Together, we can unlock the potential within every child and ensure they have the same chances in life as any other.
          </p>
          <button>Donate Now</button>
        </div>
      </section>

      <section class="help-section">
        <p>
          WHAT <span style={{ color: "var(--red)" }}>WE DO</span>
        </p>
        <div class="help-container">
          <div class="cards">
            <div class="help-img">
              <img src="assets/Parents.jpg" />
            </div>
            <h1>Parent Empowerment</h1>
            <p>
             RAP-CD forms and supports parent groups at the sub-county level, offering a platform for parents to unite, exchange experiences, and receive training in child care and income-generating activities. This initiative helps parents overcome social, emotional, and economic obstacles, enabling them to better support their children with disabilities.
            </p>
          </div>

          <div class="cards">
            <div class="help-img">
              <img src="assets/Rehabilitation.jpg" />
            </div>
            <h1>Rehabilitation</h1>
            <p>
            RAP-CD prioritizes preventing disabilities, early treatment, and access to essential medical and rehabilitation services for children with disabilities. This includes regular assessments, referrals to medical facilities, and assistance with covering medical expenses.
            </p>
          </div>

          <div class="cards">
            <div class="help-img">
              <img src="assets/Education.jpg" />
            </div>
            <h1>Inclusive Education & Vocational Training</h1>
            <p>
            RAP-CD's mission is to enhance educational opportunities for children with disabilities in the Rwenzori region. They foster inclusivity through schools, including one for the blind and deaf. Their educational program extends to vocational training in skills like tailoring, carpentry, knitting, and soap making, empowering children with practical skills for self-sufficiency.
            </p>
          </div>

          <div class="cards">
            <div class="help-img">
              <img src="assets/Sensitization.jpg" />
            </div>
            <h1>Advocacy and Sensitization</h1>
            <p>
            RAP-CD actively sensitizes the community on the rights and care of children with disabilities. They advocate for the rights of these children, defending them when violations occur. By raising awareness and challenging stigmatization, RAP-CD aims to create a more inclusive and accepting society for children with disabilities.
            </p>
          </div>
        </div>
      </section>

      <section class="about-us">
        <div class="about-us-1">
          <img src="assets/who.jpg" />
        </div>
        <div class="about-us-2">
          <div class="about-text">
            <p class="about-heading-2">WHO WE ARE?</p>
            <p class="about-heading-2"></p>
            <p class="about-heading-3">
           A  dedicated non-governmental organization founded in 2007 by parents of children with disabilities in Uganda. Our mission is to empower parents, guardians, and children with disabilities to advocate for equality of rights and opportunities. We believe in fostering inclusivity through education, medical support, and parent empowerment, all aimed at creating a more equitable and compassionate society for children with disabilities in the Rwenzori region..
            </p>
            <button Link='https://gogetfunding.com/devices-of-hope-empowering-rehabilitation-for-children/'>Donate Now</button>
          </div>
        </div>
      </section>

      <section class="feature-section">
        <p>
          FEATURED <span style={{ color: "var(--red)" }}>CAUSES</span>
        </p>

        <div class="featured-container">
          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 1.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $2,666</p>
              </div>
              <div class="feature-heading">
              Devices of Hope: Empowering Rehabilitation for Children
              </div>
              <a href="https://gogetfunding.com/devices-of-hope-empowering-rehabilitation-for-children" target="_blank">
          <button>Donate Now</button>
        </a>
            </div>
          </div>

          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 2.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>

            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $4,372</p>
              </div>
              <div class="feature-heading">
              Orbit Readers for Visually Impaired Students
              </div>
              <a href="https://gogetfunding.com/orbit-readers-for-visually-impaired-students/" target="_blank">
          <button>Donate Now</button>
        </a>        
            </div>
          </div>

          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 3.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $4,266</p>
              </div>
              <div class="feature-heading">
              Focus-40 Braille Displays
              </div>
              <a href="https://gogetfunding.com/focus-40-braille-displays/" target="_blank">
          <button>Donate Now</button>
        </a>
            </div>
          </div>
          
          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 4.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $33,212</p>
              </div>
              <div class="feature-heading">
              A Hall of Opportunities: Building for a Brighter Tomorrow
              </div>
              <a href="https://gogetfunding.com/a-hall-of-opportunities-building-for-a-brighter-tomorrow/" target="_blank">
          <button>Donate Now</button>
        </a>
            </div>
          </div>
        </div>
      </section>
{/* 
      <section class="testimonials">
        <h1>WHAT PEOPLE SAY</h1>
        <div class="dialog">
          <img src="assets/quotes.png" />
          <p style={{ marginBottom: "30px" }}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum
            optio dolore dignissimos ex quae? Similique obcaecati laborum earum
            illum quisquam, animi inventore, esse quos fugit assumenda magnam?
            Itaque, ratione! Ratione.
          </p>

          <div class="triangle"></div>
          <span>
            <span style={{ color: "var(--red)" }}>JOHN DOE</span> | NGO
          </span>
        </div>

        <div class="test-photos">
          <img src="assets/test1.png" />
          <img src="assets/test2.png" />
          <img src="assets/test3.png" />
          <img src="assets/test4.png" class="display" />
          <img src="assets/test5.png" class="display" />
        </div>
      </section> */}

<section className="volunteer bg-gray-100 py-16">
  <p className="text-2xl font-semibold text-center mb-6">
    Our <span className="text-red-600">Partners</span>
  </p>

  <div className="container mx-auto">
    <div className="md:flex justify-center">
      {/* On mobile screens, use a grid layout */}
      <div className="grid grid-cols-1 gap-6 md:hidden">
        {/* Partner 1 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center">
          <div className="help-img">
            <img src="assets/ngo1.jpg" alt="Partner 1" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Accomplish Children's Trust CIO</p>
        </div>

        {/* Partner 2 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center">
          <div className="help-img">
            <img src="assets/ngo2.png" alt="Partner 2" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Malteser International</p>
        </div>

        {/* Partner 3 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center">
          <div className="help-img">
            <img src="assets/ngo3.png" alt="Partner 3" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">CoRSU Hospital</p>
        </div>

        {/* Partner 4 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center">
          <div className="help-img">
            <img src="assets/ngo4.jpg" alt="Partner 4" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Kasese Local Government</p>
        </div>
      </div>

      {/* On larger screens, use a flex layout */}
      <div className="hidden md:flex flex-wrap justify-center">
        {/* Partner 1 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5">
          <div className="help-img">
            <img src="assets/ngo1.jpg" alt="Partner 1" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Accomplish Children's Trust CIO</p>
        </div>

        {/* Partner 2 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5">
          <div className="help-img">
            <img src="assets/ngo2.png" alt="Partner 2" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Malteser International</p>
        </div>

        {/* Partner 3 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5">
          <div className="help-img">
            <img src="assets/ngo3.png" alt="Partner 3" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">CoRSU Hospital</p>
        </div>

        {/* Partner 4 */}
        <div className="bg-white rounded-lg p-4 flex flex-col items-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5">
          <div className="help-img">
            <img src="assets/ngo4.jpg" alt="Partner 4" className="w-full h-auto" />
          </div>
          <p className="text-center mt-2">Kasese Local Government</p>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default Home;
