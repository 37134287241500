import React from "react";

const AboutCard = () => {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
            alt="feature"
            class="object-cover object-center h-full w-full"
            src="assets/about2.jpg"
          />
        </div>
        <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div class="flex flex-col mb-10 lg:items-start items-center">
      
            <div class="flex-grow">
              <h2 class="text-gray-900 text-2xl title-font font-bold mb-3">
                Background
              </h2>
              <p class="leading-relaxed text-base">
              RAPCD is a Non Governmental Organization founded in 2007 by parents of children with disabilities. It
is the Umbrella organization through which District Unions of Persons with Disabilities and the District
Community Development Departments networks with grass root children with disabilities.
It is also a network of over 10 parents support groups established at sub county levels in the districts of
Kasese.
The organization works closely with parents/guardians and families of children with disabilities aiming at
creating a unified voice of the voiceless children the Rwenzori region to challenge situations that
dehumanize children with disabilities.
Basically the aim of the organization is to strengthen and empower parents of children with disabilities to
work together to find the way to care for their children and sensitize the community on the rights and care
of children with disabilities. 
              </p>
 
            </div>
          </div>
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-bold mb-3">
                Objectives
              </h2>
              <p class="leading-relaxed text-base">
              <ol type="1">
                  <li> 1.	To bring together parents and guardians of children with disabilities in order to share ideas concerning their children in question and support each other to make their lives better.
</li>
          
                  <li> 2.	To sensitise the community on the rights and care of children with disabilities and defend those rights where they are violated or attempted to be violated
</li>

                  <li> 3.	To provide counselling and guidance to parents and guardians of disabled children and their children in order help them move away from the situation of self pity</li>
                </ol>
              </p>
            </div>
          </div>
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
              
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-bold mb-3">
              RAPCD Primary School - Illuminating Minds, Nurturing Potential
              </h2>
              <p class="leading-relaxed text-base">
              The RAPCD Primary School is a beacon of hope and opportunity in the heart of the Rwenzori region. Founded under the visionary umbrella of the RWENZORI ASSOCIATION OF PARENTS OF CHILDREN WITH DISABILITIES (RAP-CD) in 2007, the primary school is a testament to our unwavering commitment to empowering children with disabilities and fostering inclusivity in education.
              Our primary school is a model of inclusivity, where children of all abilities come together to learn, play, and build lifelong friendships. We recognize the unique potential in each child and work tirelessly to ensure that no child is left behind. In our classrooms, we embrace diversity and offer individualized support to meet the specific needs of our students.
              Over the years, our primary school has flourished. Our enrollment has expanded, our facilities have improved, and our dedication to quality education remains unwavering. We've made significant strides in our educational journey, and we continue to strive for excellence, as we look forward to a promising future.

RAPCD Primary School is not just a place of learning; it's a symbol of hope, inclusivity, and the transformative power of education. Every child who walks through our doors is met with opportunity, support, and a chance to shine. Together, we are lighting the way for a brighter, more inclusive future for all children in the Rwenzori region.





              </p>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCard;
