import React, {useState} from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace 'YOUR_FORMSPREE_ENDPOINT' with your actual Formspree endpoint
    const formEndpoint = "https://formspree.io/f/xjvqllka";

    try {
      const response = await fetch(formEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, message }),
      });

      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  return (
    
    <section className="bg-gray-100 py-16">
      <Helmet>
        <title>About us</title>
          </Helmet>
      <div className="container mx-auto max-w-5xl p-6">
        <div className="bg-white rounded-lg p-8 shadow-md">
          <h2 className="text-2xl mb-4 font-semibold text-gray-800">Contact Us</h2>
          {submitted ? (
            <p className="text-green-600">Message sent successfully!</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="text-gray-700 font-semibold">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base py-2 px-3 mt-2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="message" className="text-gray-700 font-semibold">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base py-2 px-3 mt-2 resize-none"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button
                type="submit"
                className="text-white bg-red-500 border-0 py-2 px-6 mt-4 focus:outline-none hover:bg-red-600 rounded text-lg"
              >
                Send
              </button>
            </form>
          )}
          <p className="text-sm text-gray-600 mt-3">
          </p>
          <div className="mt-6">
            <h3 className="text-xl font-semibold text-gray-800">
              Additional Contact Details
            </h3>
            <ul className="list-disc pl-6 text-gray-600 mt-2">
              <li>Email: Maali.wilson@yahoo.com</li>
              <li>Phone: +256 (774) 737-45</li>
              <li>Address: Railway Ward, Kikonzo Cell, Central Division, Kasese Municipality, Uganda  </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
