import React, { useLayoutEffect, useState } from "react";
import Stats from "../components/Stats";
import FeaturedCauses from "../components/FeaturedCauses";
import DonationCard from "../components/DonationCard";
import { Helmet } from "react-helmet";

const Causes = () => {
  const active = "bg-white text-red-500";
  const inactive = "bg-red-500 text-white";
  const [One, setOne] = useState(inactive);
  const [Two, setTwo] = useState(inactive);
  const [Three, setThree] = useState(active);

  return (
    <div className="mb-[120px] flex flex-col items-center">
      <Helmet>
        <title>Causes</title>
          </Helmet>
      <div
        className="flex justify-center items-start w-full h-[320px] bg-cover bg-no-repeat bg-right md:bg-center p-5"
        id="causes-banner"
      >
        <div className="flex flex-col w-[90%] md:w-[50%] h-full justify-around text-center text-gray-100">
          <h1 className="text-6xl tracking-tighter font-sans">Our Causes</h1>
          <p className="text-gray-200">
          Explore RAPCD's initiatives that make a difference in the lives of children with disabilities. Our diverse causes, from educational empowerment to medical support and sustainable income-generating projects, are steps towards a more inclusive, compassionate world. Join us in these transformative journeys and help us create brighter, more promising futures for these remarkable children.
          </p>
        </div>
      </div>

 
      <section class="feature-section">
        <p>
          FEATURED <span style={{ color: "var(--red)" }}>CAUSES</span>
        </p>

        <div class="featured-container">
          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 1.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $2,666</p>
              </div>
              <div class="feature-heading">
              Devices of Hope: Empowering Rehabilitation for Children
              </div>
              <a href="https://gogetfunding.com/devices-of-hope-empowering-rehabilitation-for-children" target="_blank">
          <button>Read more</button>
        </a>
            </div>
          </div>

          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 2.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>

            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $4,372</p>
              </div>
              <div class="feature-heading">
              Orbit Readers for Visually Impaired Students
              </div>
              <a href="https://gogetfunding.com/orbit-readers-for-visually-impaired-students/" target="_blank">
          <button>Read more</button>
        </a>        
            </div>
          </div>

          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 3.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $4,266</p>
              </div>
              <div class="feature-heading">
              Focus-40 Braille Displays
              </div>
              <a href="https://gogetfunding.com/focus-40-braille-displays/" target="_blank">
          <button>Read more</button>
        </a>
            </div>
          </div>
          
          <div class="featured-card">
            <div class="feature-img">
              <img src="assets/cause 4.jpg" />
              <div class="feature-bar">
                <div class="line">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <p>0%</p>
                </div>
              </div>
            </div>
            <div class="feature-text">
              <div class="stats">
                <p>Raised: $0</p>
                <p>Goal: $33,212</p>
              </div>
              <div class="feature-heading">
              A Hall of Opportunities: Building for a Brighter Tomorrow
              </div>
              <a href="https://gogetfunding.com/a-hall-of-opportunities-building-for-a-brighter-tomorrow/" target="_blank">
          <button>Read more</button>
        </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Causes;
