import React from "react";

const NewsCard = () => {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-wrap w-full mb-20">
          <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              RAP-CD Latest News
            </h1>
            <div class="h-1 w-20 bg-red-500 rounded"></div>
          </div>
          <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">
          RAP-CD is proud to announce a groundbreaking initiative aimed at transforming the lives of children with disabilities in the Rwenzori region. In our ongoing commitment to empowerment and inclusivity, we have launched an innovative educational program designed to provide these exceptional children with even more opportunities to thrive.
          </p>
        </div>
        <div class="flex flex-wrap -m-4">
          <div class="xl:w-1/4 md:w-1/2 p-4">
            <div class="bg-gray-100 p-6 rounded-lg">
              <img
                class="h-40 rounded w-full object-cover object-center mb-6"
                src="assets/wed.jpg"
                alt="content"
              />
              <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                News
              </h3>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Bwambale gets married
              </h2>
              <p class="leading-relaxed text-base">
                A former deaf student at RAP-CD Primary School finds his rib
              </p>
            </div>
          </div>
          <div class="xl:w-1/4 md:w-1/2 p-4">
            <div class="bg-gray-100 p-6 rounded-lg">
              <img
                class="h-40 rounded w-full object-cover object-center mb-6"
                src="assets/feature2.png"
                alt="content"
              />
              <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                News
              </h3>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                RAP-CD 
              </h2>
              <p class="leading-relaxed text-base">


              </p>
            </div>
          </div>
          <div class="xl:w-1/4 md:w-1/2 p-4">
            <div class="bg-gray-100 p-6 rounded-lg">
              <img
                class="h-40 rounded w-full object-cover object-center mb-6"
                src="assets/feature1.jpg"
                alt="content"
              />
              <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                News
              </h3>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                
              </h2>
              <p class="leading-relaxed text-base">

              </p>
            </div>
          </div>
          <div class="xl:w-1/4 md:w-1/2 p-4">
            <div class="bg-gray-100 p-6 rounded-lg">
              <img
                class="h-40 rounded w-full object-cover object-center mb-6"
                src="assets/giveaway.jpg"
                alt="content"
              />
              <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                News
              </h3>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
              </h2>
              <p class="leading-relaxed text-base">

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsCard;
