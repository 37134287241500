import React from "react";
import "../App.css";
import Card from "../components/Card";
import AboutCard from "../components/AboutCard";
import {Helmet} from "react-helmet";

const About = () => {
  return (
    
    <div className="flex flex-col">
      <Helmet>
        <title>About us</title>
          </Helmet>
      <div
        className="flex justify-center items-start w-full h-[320px] bg-cover bg-no-repeat bg-right md:bg-center p-5"
        id="about-banner"
      >
        <div className="flex flex-col w-[90%] md:w-[50%] h-full justify-around text-center text-gray-100">
          <h1 className="text-6xl tracking-tighter  font-sans">About Us</h1>
          <p className="text-gray-200">
          We are the RWENZORI ASSOCIATION OF PARENTS OF CHILDREN WITH DISABILITIES (RAP-CD), a non-governmental organization founded in 2007 by parents who share a common mission - to create a world where children with disabilities are seen, heard, and provided with equal opportunities. At RAP-CD, we tirelessly work to empower parents, guardians, and these extraordinary children through educational initiatives, medical support, and advocacy. Our commitment to inclusivity and compassion fuels our journey, as we strive to make a lasting impact in the Rwenzori region and beyond.





          </p>
        </div>
      </div>

      <AboutCard />
      <div className="w-full bg-[var(--red)] py-10 pb-[100px] flex flex-col items-center justify-center">
  <h1 className="text-center text-2xl tracking-tighter font-bold text-white mb-7">
    OUR MILESTONES
  </h1>
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 justify-center">
    <Card
      heading="Establishment of Inclusive Educational Facilities"
      para="One significant milestone for RAP-CD might be the establishment and growth of inclusive educational facilities, including primary and secondary schools. These institutions can serve as models for inclusivity and quality education for children with disabilities, helping them access opportunities they might not have had otherwise."
      img="/assets/ga5.jpg"
    />
    <Card
      heading="Advocacy and Community Sensitization"
      para="RAP-CD's successful advocacy efforts and community sensitization campaigns could be another milestone. If they've been able to change attitudes and perceptions towards children with disabilities, raise awareness about their rights, and influence policies that benefit these children, it would mark a significant achievement."
      img="/assets/sen.jpg"
    />
    <Card
      heading="Establishing Sustainable Income-Generating Initiatives"
      para="For instance, the thriving duck project represents the successful launch of ventures aimed at benefiting families with children who have disabilities. These initiatives not only bolster the economic stability of these families but also establish enduring streams of income, fostering the long-term financial empowerment of the entire community."
      img="/assets/duck.jpg"
    />
  </div>
</div>

    </div>
  );
};

export default About;
